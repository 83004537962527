export default (id, scriptUrl) => new Promise(done => {
    (function(d, s, id){
        var js, fjs = d.getElementsByTagName(s)[0];
        if (d.getElementById(id)){ return; }
        js = d.createElement(s);
        js.id = id;
        js.onload = () => done()
        js.src = scriptUrl;
        fjs.parentNode.insertBefore(js, fjs);
    }(document, 'script', id))
})