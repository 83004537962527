import { isNull } from 'util'

var find = require('lodash/find')
var map = require('lodash/map')
var join = require('lodash/join')
var isEmpty = require('lodash/isEmpty')
var pick = require('lodash/pick')
var omit = require('lodash/omit')
var merge = require('lodash/merge')

let template = ({
    careersPageUrl,
    contactPageUrl,
    api,
    text: {
        exploreLong,
        exploreShort,
        next,
        cancel,
    },
    urls: {
        careers: {
            text:careers_text,
            url:careers_url,
        },
        contact: {
            text:contact_text,
            url:contact_url,
        },
    },
    features,
    aboutUL,
    mobileLinks,
    languageNames,
    languageNamePreferred,
    lang,
    multisite,
    log,
    logo
}) => {

    if (log) console.log('multisite', multisite, lang)

    return (`<svg style="display:none">
    <symbol id="ul-global-logo" viewBox="0 0 486.8 200" id=".2822794967375508" xmlns="http://www.w3.org/2000/svg">
 <path class="ag" d="M95.5 175c43.5 0 74.5-35 74.5-75s-31.5-70-70-70c-4 0-9 .5-11.5 1 4.5-1.5 12-2 16-2 51 0 81.5 41 81.5 85 0 51.5-38.5 86-86 86C45 200 0 155 0 100c0-19.5 6-45 26.5-67.5-13 18-16.5 36-16.5 54C10 133 41.5 175 95.5 175z" fill="#fff"/>
 <path class="ag" d="M104.5 25C61 25 30 60 30 100s31.5 70 70 70c4 0 9-.5 11.5-1-4.5 1.5-12 2-16 2-51 0-81.5-41-81.5-85 0-51.5 38.5-86 86-86 55 0 100 45 100 100 0 19.5-6 45-26.5 67.5 13-18 16.5-36 16.5-54C190 67 158.5 25 104.5 25z" fill="#fff"/>
 <path class="ag" d="M245.1 124.8c-10 0-15.5-6-16-15.5h9.5c0 5 2.5 7.5 6 7.5 2.5 0 5-1 5-4.5s-2.5-5.5-7-8l-3.5-2c-5-2.5-9-6-9-13 0-9 6.5-13.5 15-13.5 9 0 14.5 5.5 14.5 14.5h-9.5c-.5-5-2-7.5-5.5-7.5-3 0-4.5 2-4.5 4.5 0 3.5 3 5.5 6.5 7.5l3 2c3.5 2 10 5.5 10 14.5.5 9.5-7 13.5-14.5 13.5zm34-.5c8.5 0 15.5-6 15.5-18s-7-18-15.5-18-15.5 6.5-15.5 18c0 12.1 7 18.5 15.5 18zm0-7.9c-3.5 0-6-3.5-6-10s2.5-10 6-10 6 3.5 6 10-2 10-6 10zm20.5 7.9h9.5V74.9h-9.5v49.4zm44.4-.5v-34h-9.5v21c0 4-2 6-5 6s-5-2-5-6V89.3H315v23.5c0 7.5 4 12 10.5 12 4.5 0 7-2 8.5-4.5h.5l1 3.5h8.5zm38.5-38.9c3 0 5.5-2.5 5.5-5.5s-2.5-5.5-5.5-5.5c-3.5 0-6 2.5-6 5.5s3 5.5 6 5.5zm-5 38.9h9.5V89.3h-9.5v34.5zm30.4.5c8.5 0 15.5-6 15.5-18s-7-18-15.5-18-15.5 6.5-15.5 18c0 12.1 7 18 15.5 18zm0-7.9c-3.5 0-6-3.5-6-10s2.5-10 6-10 6 3.5 6 10-2.5 10-6 10zm66.4 7.4c6.5 0 12.5-3 12.5-10s-5-9.5-9.5-11l-2.5-1c-2.5-1-4.5-2-4.5-4s1-3 3-3c2.5 0 4 1.5 4 4h9c0-6-4.5-10.5-12.5-10.5-7 0-12.5 3.5-12.5 10.5 0 6.5 5 8.5 9 10l2.5 1c3 1 5 2 5 4.5 0 2-1.5 3-3.5 3-2.5 0-4-1-4.5-4.5h-9c.6 7.5 5 11.5 13.5 11zm-109.8.5c2 0 4.5 0 6-.5v-7c-1 .5-2 .5-3 .5-3 0-4-2-4-4V96.8h8.5v-7.5h-8.5v-9H355v4c0 3.5-1.5 5-5 5h-1.5v7.5h6v18c0 5.5 3 10 10 9.5zm63.4-34.9v34.5h9.5v-21c0-4.5 2-6.5 5-6.5s5 2 5 6v21.5h9.5v-23.5c0-7.5-4-12-10.5-12-4.5 0-7 2-8.5 4.5h-.5l-1-3.5h-8.5zM56.6 52.5v50.1c0 14.2 8 20.8 21.8 20.8 16.6 0 21.8-9 21.8-20.8V52.5H84v51.8c0 2.9-1.3 6.6-5.5 6.6-4.6 0-5.6-2.9-5.6-6.2V52.5H56.6m50 94.3V77.5h16.2v55.4h19.8v13.9h-36" fill="#fff"/>
 </symbol>
 <symbol id="ul-global-arrow" viewBox="0 0 10 9"><defs><polygon points="10 8.9 0 8.9 0 0 10 0 10 8.9"></polygon></defs><g fill="none"><mask fill="white"><use xlink:href="#path-1"></use></mask><path d="M5 1.6L6.8 3.5 0.9 3.5C0.4 3.5 0 3.9 0 4.4 0 5 0.4 5.4 0.9 5.4L6.8 5.4 5 7.2C4.6 7.6 4.6 8.2 5 8.6 5.2 8.8 5.4 8.9 5.7 8.9 5.9 8.9 6.1 8.8 6.3 8.6L9.7 5.1C9.7 5.1 9.7 5.1 9.7 5.1 9.7 5.1 9.7 5.1 9.7 5.1 9.8 5.1 9.8 5 9.8 5 9.8 5 9.9 4.9 9.9 4.9 9.9 4.9 9.9 4.8 9.9 4.8 9.9 4.8 9.9 4.7 9.9 4.7 10 4.7 10 4.7 10 4.6 10 4.5 10 4.4 10 4.3 10 4.2 10 4.2 9.9 4.2 9.9 4.1 9.9 4.1 9.9 4.1 9.9 4 9.9 4 9.9 4 9.9 4 9.8 3.9 9.8 3.9 9.8 3.9 9.8 3.8 9.7 3.8 9.7 3.8 9.7 3.8 9.7 3.8L6.3 0.3C6-0.1 5.4-0.1 5 0.3 4.6 0.7 4.6 1.3 5 1.6" mask="url(#mask-2)" fill="#CE1B2A"></path></g></symbol>
    <symbol id="ul-global-close" viewBox="0 0 39.18 39.18"><circle style="stroke:none;" class="circle" cx="19.59" cy="19.59" r="18.09"></circle><line style="stroke-miterlimit:10;stroke-width:3px;stroke-linecap:round;" x1="14.92" y1="14.92" x2="24.26" y2="24.26"></line><line style="stroke-miterlimit:10;stroke-width:3px;stroke-linecap:round;" x1="24.26" y1="14.92" x2="14.92" y2="24.26"></line></symbol>
    <symbol id="ul-global-down-arrow" viewBox="0 0 10 6"><g stroke-width="1" fill-rule="evenodd"><path d="M5.27261369,2.67193452 L2.77465536,5.47037202 C2.40840536,5.84687202 2.40840536,6.45758631 2.77465536,6.83408631 C2.95757202,7.02222917 3.19757202,7.11651488 3.43757202,7.11651488 C3.67736369,7.11651488 3.91736369,7.02222917 4.10048869,6.83408631 L7.48298869,3.35472917 C7.53007202,3.3048006 7.56778036,3.25765774 7.60090536,3.20687202 C7.61694702,3.18222917 7.62798869,3.15544345 7.64153036,3.12972917 C7.65715536,3.10037202 7.67444702,3.07208631 7.68694702,3.04101488 C7.70007202,3.00822917 7.70778036,2.97394345 7.71736369,2.94008631 C7.72486369,2.91372917 7.73486369,2.88844345 7.74007202,2.8608006 C7.76423869,2.73608631 7.76423869,2.60772917 7.74007202,2.48301488 C7.73486369,2.45537202 7.72486369,2.43008631 7.71736369,2.40372917 C7.70778036,2.36987202 7.70007202,2.33558631 7.68694702,2.3028006 C7.67444702,2.27172917 7.65715536,2.24365774 7.64153036,2.21408631 C7.62798869,2.18837202 7.61694702,2.16158631 7.60090536,2.13694345 C7.56778036,2.08615774 7.53007202,2.03901488 7.48861369,1.99572917 C7.48694702,1.99401488 4.10028036,-1.49027083 4.10028036,-1.49027083 C3.73423869,-1.86677083 3.14069702,-1.86677083 2.77465536,-1.49027083 C2.40840536,-1.11377083 2.40840536,-0.503056548 2.77465536,-0.126556548 L5.27261369,2.67193452 Z" transform="translate(5.129082, 2.671935) rotate(-270.000000) translate(-5.129082, -2.671935) "></path></g></symbol>
    </svg>
    <div class="ul-global-nav-wrapper">
    
        <div class="ul-global-nav-wrapper-logo">
            <a id="ul-global-nav-overlay-trigger" href="#">
            `+( logo ? `
                <svg class="ul-global-nav-logo"><use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="#ul-global-logo"></use></svg>
            ` : logo== false?` <divlogo class="logo-removed"></divlogo> ` : '' )+`
                <span class="md-screen-hide">${exploreLong}
<!--                <svg class="down-arrow"><use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="#ul-global-down-arrow"></use></svg>-->
                </span>
                <span class="md-screen-only">${exploreShort}
<!--                <svg class="down-arrow"><use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="#ul-global-down-arrow"></use></svg>-->
                </span>
            </a>
        </div>
<!--        <ul class="ul-global-nav-wrapper-nav">-->
<!--            -->
<!--        </ul>  -->
        <ul class="ul-global-nav-wrapper-nav">
        `+(join(map(aboutUL.links, link => `<li class="md-screen-hide"><a href="${link.url}" target="_blank">${link.text}</a></li>`), ''))+`
            `+( isNull(careersPageUrl) ? `<li class="md-screen-hide"><a href="https://www.ul.com/careers">${careers_text}</a></li>` : careersPageUrl !== false ? `<li class="md-screen-hide"><a href="${careersPageUrl}">${careers_text}</a></li>` : '' )+`
            `+( isNull(contactPageUrl) ? `<li class="md-screen-hide"><a href="https://www.ul.com/about/locations">${contact_text}</a></li>` : contactPageUrl !== false ? `<li class="md-screen-hide"><a href="${contactPageUrl}" target="_blank">${contact_text}</a></li>` : '' )
    +
    ( isEmpty(multisite) ? '' : `<li id="ul-global-language-selector-container">
    <a id="ul-global-language-trigger" aria-label="Selected Language: ${languageNames[languageNamePreferred || lang]}" href="#" class="lang-${lang} wwwulcomicon-langswitch">${languageNames[languageNamePreferred || lang]}<svg class="down-arrow"><use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="#ul-global-down-arrow"></use></svg></a>
    <ul id="ul-global-language-selector">
    `+
    `<li><a href="${multisite[languageNamePreferred || lang]}" aria-label="Selected Language: ${languageNames[languageNamePreferred || lang]}" href-lang="${lang}" class="lang-${lang} wwwulcomicon-selectedlang">${languageNames[languageNamePreferred || lang]}<svg class="down-arrow"><use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="#ul-global-down-arrow"></use></svg></a></li>`
    +
        join(
            map(multisite, (link, key) => (languageNamePreferred || lang) == key ? '' : `<li><a href="${link}" aria-label="${languageNames[key]}" href-lang="${key}" class="lang-${key} ">${languageNames[key]}<svg class="down-arrow"><use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="#ul-global-down-arrow"></use></svg></a></li>`)
        , '')
    +`
               </ul>
           </li>`)
    +`
        </ul>
    </div>
    `)
}

const toggleClass = (el, className) => el.classList.toggle(className)

export default (config) => {
    let container = 'ul-global-nav'
    let attachTo = config.attachNaaSTo ? config.attachNaaSTo : 'body'

    if ( config.force && jQuery('#' + container).length ) jQuery('#' + container).remove()

    if ( jQuery('#' + container).length ) return

    let {
        languageNamePreferred,
        languagePacks,
        multisite,
        lang,
    } = config

    let languageNames = languagePacks[lang]

    // if (config.log) console.log('NaaS config', config)
    config.languageNames = languageNames['languageNames']

    let classes = 'ul-sticky'

    if ( typeof config.NaaS === 'string' ) {
        classes += ` ${config.NaaS}`
    }

    jQuery(attachTo).prepend(
        '<div id="'+ container +'" class="'+ classes +'" />'
    )

    if (config.log) console.log('NaaS Loaded', config)

    // console.log('multisite', multisite)

    jQuery('#' + container ).html(template({
        careersPageUrl: null,
        contactPageUrl: null,
        api: config.api,
        languageNames,
        ...config,
        ...languagePacks[lang],
        log: config.log,
        multisite,
    }))

    /**
     * Binding events
     */
    jQuery(window).on('scroll', (arg) => {
        jQuery('.ul-top').css('top', '42px !important')
        jQuery('.ul-top').css('bottom', 'inherit !important')
    })
    // jQuery('.ul-top').on('click', () => toggleClass(document.querySelector('#' + container), 'show'))

    // Close Language Switcher from the outsite
    jQuery(window).on('click', e => {
        let isOpen = jQuery('#ul-global-language-selector').hasClass('show')
        let id = jQuery(e.target).attr('id')

        if ( isOpen == true && undefined == id ) {
            jQuery('#ul-global-language-selector').removeClass('show')
        }
    })

    // Explore UL
    jQuery('#ul-global-nav-overlay-trigger').on('click', (e) => {
        //toggleClass(document.querySelector('#' + container), 'show')
        window.open("https://www.ul.com");
        return false
    })

    // Explore UL close
    jQuery('#ul-global-modal-close').on('click', (e) => {
        window.open("https://www.ul.com");
        return false
    })

    // Language Switcher / Click & Close
    jQuery('#ul-global-language-selector a:not(:first)').on('click', (e) => {
        window.location = jQuery(e.target).attr('href')
        return false
    })
    jQuery('#ul-global-language-selector a:first').on('click', (e) => {
        console.log('OK')
        toggleClass(
            document.querySelector('#ul-global-language-selector'),
            'show'
        )
        return false
    })

    // Language Switcher / Dropdown
    jQuery('#ul-global-language-trigger').on('click', (e) => {
        toggleClass(
            document.querySelector('#ul-global-language-selector'),
            'show'
        )
        return false
    })
}
