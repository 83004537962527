require('../../assets/scss/gnaas.scss')

let injectStyles = require('../InjectStyles').default
let injectScript = require('../injectScript').default
let getLanguagePacks = require('../getLanguagePacks').default
let config = require('../../config').default
let inject_FaaS = require('../FaaS').default
let inject_NaaS = require('../NaaS').default

module.exports = (options) => {
    if ( 'undefined' === jQuery ) {
        console.error('UL GNaaS: setup requires jQuery loaded before this script. Exiting...')
    }

    let lang = options ? options.lang && options.lang.length > 1 ? options.lang : 'en' : 'en'

    options = {
        ...{
            env: 'prod',
            GDPR: false,
            log: true,
            force: false,
            attachNaaSTo: null,
            attachFaaSTo: null,
            impressum: false,
            logo: true,
            NaaS: true,
            FaaS: true,
            forceSticky: false,
        },
        ...options,
    }

    let params = {
        sticky_url: `https://commons.ul.com/gnaas/${options.env}/stickyfill/stickyfill.min.js`,
        ...options,
        ...config[options.env],
        lang,
    }

    if (options.log) console.log('GnaaS init with', params)

    params.api = config[params.env].baseUrl

    injectStyles(params.stylesUrl)
        .then(() => getLanguagePacks(params.languagePackUrl))
        .then((languagePacks) => {
            params.languagePacks = languagePacks || {}
            params.languagePacks[lang] || console.error('GnaaS: Language pack not found', lang, params.languagePacks)
            return languagePacks
        })
        .then(() => {
            if ( params.NaaS ) {
                inject_NaaS(params)
                injectScript('stickyfill', params.sticky_url).then(() => {
                    Stickyfill.add(document.querySelectorAll('.ul-sticky'))
                    if (params.forceSticky) Stickyfill.forceSticky();
                })
            }
        })
        .then(() => params.FaaS && inject_FaaS(params))
        .then(() => console.log('GNaaS by UL'))
}