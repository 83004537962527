var find = require('lodash/find')
var isEmpty = require('lodash/isEmpty')
var isUndefined = require('lodash/isUndefined')
var isObject = require('lodash/isObject')

let template = ({
  copyright,
  online_policies_title,
  online_policies_url,
  about_cookies_title,
  about_cookies_url,
  data_subject_access_title,
  data_subject_access_url,
  social_media,
  impressum_url,
  impressum_label,
  market_surveillance_url,
  market_surveillance_label,

}) => (`<footer class="wwwulcom-footer">
<div class="wwwulcom-footer__bottom">
    <div class="wwwulcom-wrapper">
        <div class="wwwulcom-footer-logo">
            <a href="https://www.ul.com/" target="_blank" aria-label="UL Logo">
                <svg xmlns="http://www.w3.org/2000/svg" class="ll_footer" viewBox="0 0 486.8 200">
                <defs>
                <style>.g{fill:#fff}</style>
                </defs>
                  <g id="a"></g>
                    <g id="b">
                      <g id="c">
                        <g id="d">
                          <g id="e">
                          <g id="f">
                            <path class="g" d="M95.5 175c43.5 0 74.5-35 74.5-75s-31.5-70-70-70c-4 0-9 .5-11.5 1 4.5-1.5 12-2 16-2 51 0 81.5 41 81.5 85 0 51.5-38.5 86-86 86C45 200 0 155 0 100c0-19.5 6-45 26.5-67.5-13 18-16.5 36-16.5 54C10 133 41.5 175 95.5 175Z"></path>
                            <path class="g" d="M104.5 25C61 25 30 60 30 100s31.5 70 70 70c4 0 9-.5 11.5-1-4.5 1.5-12 2-16 2-51 0-81.5-41-81.5-85 0-51.5 38.5-86 86-86 55 0 100 45 100 100 0 19.5-6 45-26.5 67.5 13-18 16.5-36 16.5-54C190 67 158.5 25 104.5 25Z"></path>
                          </g>
                            <path class="g" d="M245.1 124.8c-10 0-15.5-6-16-15.5h9.5c0 5 2.5 7.5 6 7.5 2.5 0 5-1 5-4.5s-2.5-5.5-7-8l-3.5-2c-5-2.5-9-6-9-13 0-9 6.5-13.5 15-13.5 9 0 14.5 5.5 14.5 14.5h-9.5c-.5-5-2-7.5-5.5-7.5-3 0-4.5 2-4.5 4.5 0 3.5 3 5.5 6.5 7.5l3 2c3.5 2 10 5.5 10 14.5.5 9.5-7 13.5-14.5 13.5Zm34-.5c8.5 0 15.5-6 15.5-18s-7-18-15.5-18-15.5 6.5-15.5 18c0 12.1 7 18.5 15.5 18Zm0-7.9c-3.5 0-6-3.5-6-10s2.5-10 6-10 6 3.5 6 10-2 10-6 10Zm20.5 7.9h9.5V74.9h-9.5v49.4Zm44.4-.5v-34h-9.5v21c0 4-2 6-5 6s-5-2-5-6V89.3H315v23.5c0 7.5 4 12 10.5 12 4.5 0 7-2 8.5-4.5h.5l1 3.5h8.5Zm38.5-38.9c3 0 5.5-2.5 5.5-5.5s-2.5-5.5-5.5-5.5c-3.5 0-6 2.5-6 5.5s3 5.5 6 5.5Zm-5 38.9h9.5V89.3h-9.5v34.5Zm30.4.5c8.5 0 15.5-6 15.5-18s-7-18-15.5-18-15.5 6.5-15.5 18c0 12.1 7 18 15.5 18Zm0-7.9c-3.5 0-6-3.5-6-10s2.5-10 6-10 6 3.5 6 10-2.5 10-6 10Zm66.4 7.4c6.5 0 12.5-3 12.5-10s-5-9.5-9.5-11l-2.5-1c-2.5-1-4.5-2-4.5-4s1-3 3-3c2.5 0 4 1.5 4 4h9c0-6-4.5-10.5-12.5-10.5-7 0-12.5 3.5-12.5 10.5 0 6.5 5 8.5 9 10l2.5 1c3 1 5 2 5 4.5 0 2-1.5 3-3.5 3-2.5 0-4-1-4.5-4.5h-9c.6 7.5 5 11.5 13.5 11Zm-109.8.5c2 0 4.5 0 6-.5v-7c-1 .5-2 .5-3 .5-3 0-4-2-4-4V96.8h8.5v-7.5h-8.5v-9H355v4c0 3.5-1.5 5-5 5h-1.5v7.5h6v18c0 5.5 3 10 10 9.5Zm63.4-34.9v34.5h9.5v-21c0-4.5 2-6.5 5-6.5s5 2 5 6v21.5h9.5v-23.5c0-7.5-4-12-10.5-12-4.5 0-7 2-8.5 4.5h-.5l-1-3.5h-8.5ZM56.6 52.5v50.1c0 14.2 8 20.8 21.8 20.8 16.6 0 21.8-9 21.8-20.8V52.5H84v51.8c0 2.9-1.3 6.6-5.5 6.6-4.6 0-5.6-2.9-5.6-6.2V52.5H56.6m50 94.3V77.5h16.2v55.4h19.8v13.9h-36"></path>
                        </g>
                      </g>
                    </g>
                  </g>
                </svg>
                </a>
        </div>
        <div class="wwwulcom-footer-copyright">
            <p>${copyright}</p>
            <ul class="wwwulcom-policy-links">
                `+ (impressum_url ? `<li class="wwwulcomicon">
                <a href="${impressum_url}" target="_blank">${impressum_label}</a>
            </li>` : '') +`
                <li class="wwwulcomicon">
                    <a href="${online_policies_url}" target="_blank">${online_policies_title}</a>
                </li>
                <li class="wwwulcomicon">
                    <a href="${about_cookies_url}" target="_blank">${about_cookies_title}</a>
                </li>
                <li class="wwwulcomicon wwwulcomicon-external">
                    <a href="${data_subject_access_url}" target="_blank">${data_subject_access_title}</a>
                </li>
                `+ (market_surveillance_url ? `<li class="wwwulcomicon">
                    <a href="${market_surveillance_url}" target="_blank">${market_surveillance_label}</a>
                </li> `: '') +`
                <li class="wwwulcomicon"><div id="teconsent"></div></li>
            </ul>
        </div>
        <nav class="wwwulcom-social-nav">
            <ul>
                ${social_media}
            </ul>
        </nav>
    </div>
</div>
</footer>`)

export default (config) => {
  let container = 'ul-global-footer'
  let {
    languagePacks,
    lang,
    impressum,
    marketSurveillance,
  } = config
  
  var langPack = languagePacks[lang]
  var social = config['social'] || languagePacks[lang].social

  let attachTo = config.attachFaaSTo ? config.attachFaaSTo : 'body'

  // if forced, remove the existing footer from the page
  if ( config.force && jQuery('#' + container).length ) jQuery('#' + container).remove()
  
  // Don't add the footer if it already exist on the page.
  if ( jQuery('#' + container).length ) return

  // if (config.log) console.log('using language pack', lang)
  // if (config.log) console.log('using social pack', social)
  // console.log('config', config)

  jQuery(attachTo).append(
      '<div id="'+ container +'" class="ul-sticky" />'
  )

  if (config.log) console.log('FaaS Loaded', config)

  var copyright = langPack.copyright.text
  copyright = copyright.replace('$year', new Date().getFullYear())
  var online_policies_title = langPack.urls_footer.online_policies.text
  var online_policies_url = langPack.urls_footer.online_policies.url_faas
  var about_cookies_title = langPack.urls_footer.about_cookies.text
  var about_cookies_url = langPack.urls_footer.about_cookies.url_faas
  var data_subject_access_title = langPack.urls_footer.data_subject_access.text
  var data_subject_access_url = langPack.urls_footer.data_subject_access.url_faas
  var impressum_url = impressum ? langPack.urls_footer.impressum.url_impressum : ''
  //var impressum_url = impressum ? "https://www.ul.com/impressum" : ''
  //var impressum_label = impressum ? "Impressum" : ''
  var impressum_label = impressum ? langPack.urls_footer.impressum.text : ''
  var market_surveillance_url =  marketSurveillance ? langPack.urls_footer.marketSurveillance.url_marketSurveillance : ''
  var market_surveillance_label =  marketSurveillance ? langPack.urls_footer.marketSurveillance.text: ''
  let ln, tw, ig, yt, fb
  
  if ( social ) {
    let {
      facebook,
      instagram,
      youtube,
      linkedin,
      twitter
    } = social

    ln = linkedin ? `<li class="wwwulcomicon wwwulcomicon-linkedin"><a href="${linkedin}" target="_blank">LinkedIn</a></li>` : ''
    tw = twitter ? `<li class="wwwulcomicon wwwulcomicon-twitter"><a href="${twitter}" target="_blank">Twitter</a></li>` : ''
    ig = instagram ? `<li class="wwwulcomicon wwwulcomicon-instagram"><a href="${instagram}" target="_blank">Instagram</a></li>` : ''
    yt = youtube ? `<li class="wwwulcomicon wwwulcomicon-youtube"><a href="${youtube}" target="_blank">Youtube</a></li>` : ''
    fb = facebook ? `<li class="wwwulcomicon wwwulcomicon-facebook"><a href="${facebook}" target="_blank">Facebook</a></li>` : ''
  } else {
    ln = `<li class="wwwulcomicon wwwulcomicon-linkedin"><a href="${linkedin}" target="_blank">LinkedIn</a></li>`
    tw = `<li class="wwwulcomicon wwwulcomicon-twitter"><a href="${twitter}" target="_blank">Twitter</a></li>`
    ig = `<li class="wwwulcomicon wwwulcomicon-instagram"><a href="${instagram}" target="_blank">Instagram</a></li>`        
    yt = `<li class="wwwulcomicon wwwulcomicon-youtube"><a href="${youtube}" target="_blank">Youtube</a></li>`
    fb = `<li class="wwwulcomicon wwwulcomicon-facebook"><a href="${facebook}" target="_blank">Facebook</a></li>`
  }

  let social_media = ln + tw + ig + yt + fb
  
  jQuery('#' + container ).html(template({
    copyright,
    online_policies_title,
    online_policies_url,
    about_cookies_title,
    about_cookies_url,
    data_subject_access_title,
    data_subject_access_url,
    social_media,
    impressum_url,
    impressum_label,
    market_surveillance_url,
    market_surveillance_label
  }))
}
