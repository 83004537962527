const keep_fresh = Math.floor(Math.random()*10000)

export default {
    dev: {
        stylesUrl: 'https://commons.ul.com/gnaas/dev/index.css?cache=' + keep_fresh,
        baseUrl: 'https://commons.ul.com/gnaas/dev/',
        languagePackUrl: 'https://commons.ul.com/gnaas/dev/languagePack.json',
    },
    test: {
        stylesUrl: 'https://commons.ul.com/gnaas/test/index.css?cache=' + keep_fresh,
        baseUrl: 'https://commons.ul.com/gnaas/test/',
        languagePackUrl: 'https://commons.ul.com/gnaas/test/languagePack.json',
    },
    prod: {
        stylesUrl: 'https://commons.ul.com/gnaas/prod/index.css?cache=' + keep_fresh,
        baseUrl: 'https://commons.ul.com/gnaas/prod/',
        languagePackUrl: 'https://commons.ul.com/gnaas/prod/languagePack.json',
    },
    secret:{
        stylesUrl: 'https://commons.ul.com/gnaas/secret/index.css?cache=' + keep_fresh,
        baseUrl: 'https://commons.ul.com/gnaas/secret/',
        languagePackUrl: 'https://commons.ul.com/gnaas/secret/languagePack.json',
    }
}